define("oversight/pods/projects/show/data/experiments/show/studies/show/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: {},
    _routeKey: 'studies.show',
    model: function model(_ref) {
      var study_id = _ref.study_id;

      var _this$modelFor = this.modelFor('projects.show.data.experiments.show'),
          breadCrumbs = _this$modelFor.breadCrumbs;

      var study = this.store.findRecord('study', study_id);
      return Ember.RSVP.hash({
        breadCrumbs: breadCrumbs,
        study: study
      });
    },
    afterModel: function afterModel(model) {
      var breadCrumbs = model.breadCrumbs,
          study = model.study;
      var breadCrumb = this.breadCrumb;
      var name = Ember.get(study, 'name');
      Ember.set(model, 'breadCrumbs', breadCrumbs.concat(name));
      Ember.set(breadCrumb, 'title', name);
    }
  });

  _exports.default = _default;
});
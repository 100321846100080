define("oversight/helpers/file-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  var fileSize = function fileSize(params) {
    var bytes = parseInt(params[0], 10);

    if (isNaN(bytes)) {
      return;
    }

    var idx = 0;
    var unit = UNITS[idx];
    var value = bytes;

    while (value >= 1024) {
      value = value / 1024;
      idx = idx + 1;
      unit = UNITS[idx];
    }

    return "".concat(value.toFixed(0), " ").concat(unit);
  };

  var _default = Ember.Helper.helper(fileSize);

  _exports.default = _default;
});
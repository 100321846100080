define("oversight/pods/application/controller", ["exports", "oversight/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var version = _environment.default.APP.version;

  var _default = Ember.Controller.extend({
    version: version,
    online: true,
    init: function init() {
      this._super.apply(this, arguments);

      this._addStatusListener();

      this._initStatus();
    },
    _addStatusListener: function _addStatusListener() {
      window.addEventListener('online', this._updateStatus.bind(this));
      window.addEventListener('offline', this._updateStatus.bind(this));
    },
    _updateStatus: function _updateStatus(event) {
      Ember.set(this, 'online', event.type === 'online');
    },
    _initStatus: function _initStatus() {
      Ember.set(this, 'online', window.navigator.onLine);
    }
  });

  _exports.default = _default;
});
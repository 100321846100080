define("oversight/pods/projects/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6k9xrxAC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"project--header\"],[8],[0,\"\\n  \"],[1,[28,\"project-header\",null,[[\"title\"],[[28,\"concat\",[[24,[\"model\",\"screenId\"]],\" \",[24,[\"model\",\"name\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"project-nav\",null,null,{\"statements\":[[0,\"    \"],[4,\"link-to\",null,[[\"route\"],[\"projects.show.dashboard\"]],{\"statements\":[[0,\"Dashboard\"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"projects.show.data\"]],{\"statements\":[[0,\"Data\"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"projects.show.team\"]],{\"statements\":[[0,\"Team\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"project--content-scroll\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/projects/show/template.hbs"
    }
  });

  _exports.default = _default;
});
define("oversight/pods/projects/show/data/experiments/show/studies/show/series/show/index/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: null,
    _routeKey: 'series.show',
    model: function model() {
      var _this$modelFor = this.modelFor('projects.show.data.experiments.show.studies.show.series.show'),
          breadCrumbs = _this$modelFor.breadCrumbs,
          series = _this$modelFor.series;

      var images = Ember.get(series, 'images');
      images.then(function (collection) {
        collection.reload();
      });
      return Ember.RSVP.hash({
        breadCrumbs: breadCrumbs,
        series: series,
        images: images
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.setProperties(controller, model);
    }
  });

  _exports.default = _default;
});
define("oversight/app", ["exports", "oversight/resolver", "ember-load-initializers", "oversight/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    engines: {
      site: {
        dependencies: {
          services: ['behavior', 'current-organization', 'flash-messages', 'store', 'session', 'settings'],
          externalRoutes: {
            application: 'application',
            login: 'login',
            logout: 'logout'
          }
        }
      }
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});
define("oversight/pods/components/bread-crumb/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+WWN4HJJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"route\",\"linkable\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"route\",\"id\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[[24,[\"linkClass\"]],[24,[\"route\",\"path\"]],[24,[\"route\",\"id\"]]]],{\"statements\":[[0,\"      \"],[1,[24,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[[24,[\"linkClass\"]],[24,[\"route\",\"path\"]]]],{\"statements\":[[0,\"      \"],[1,[24,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[24,[\"route\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/bread-crumb/template.hbs"
    }
  });

  _exports.default = _default;
});
define("oversight/pods/projects/show/data/data-resource-groups/show/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumbs: [],
    _routeKey: 'projects.show',
    model: function model(_ref) {
      var data_resource_group_id = _ref.data_resource_group_id;

      var _this$modelFor = this.modelFor('projects.show.data'),
          breadCrumbs = _this$modelFor.breadCrumbs;

      var dataResourceGroup = this.store.findRecord('data-resource-group', data_resource_group_id);
      return Ember.RSVP.hash({
        breadCrumbs: breadCrumbs,
        dataResourceGroup: dataResourceGroup
      });
    },
    afterModel: function afterModel(model) {
      var breadCrumbs = model.breadCrumbs,
          dataResourceGroup = model.dataResourceGroup;
      var currentId = [];
      var currentBreadCrumbs = breadCrumbs.toArray();
      var segments = Ember.get(dataResourceGroup, 'segments');
      currentId.push(segments.shift());
      currentId.push(segments.shift());
      var breadCrumb = segments.map(function (segment) {
        currentId.push(segment);
        currentBreadCrumbs.push(segment);
        return {
          title: segment,
          path: 'projects.show.data.data-resource-groups.show',
          id: currentId.join('/'),
          linkable: currentId.length <= segments.length + 1
        };
      });
      Ember.set(model, 'breadCrumbs', currentBreadCrumbs);
      Ember.set(this, 'breadCrumbs', breadCrumb);
    }
  });

  _exports.default = _default;
});
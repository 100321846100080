define("oversight/pods/projects/show/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: null,
    model: function model(_ref) {
      var project_id = _ref.project_id;
      return this.store.findRecord('project', project_id);
    }
  });

  _exports.default = _default;
});
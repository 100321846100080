define("oversight/pods/projects/show/data/ndp-resource-groups/show/index/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: {
      title: 'ndp-resource-groups.show.index'
    },
    _routeKey: 'projects.show',
    model: function model() {
      var _this$modelFor = this.modelFor('projects.show.data.ndp-resource-groups.show'),
          breadCrumbs = _this$modelFor.breadCrumbs,
          ndpResourceGroup = _this$modelFor.ndpResourceGroup;

      var ndpResourceGroups = Ember.get(ndpResourceGroup, 'ndpResourceGroups');
      var ndpResourceFiles = Ember.get(ndpResourceGroup, 'ndpResourceFiles');

      this._reload(ndpResourceGroups);

      this._reload(ndpResourceFiles);

      return Ember.RSVP.hash({
        breadCrumbs: breadCrumbs,
        ndpResourceGroup: ndpResourceGroup,
        ndpResourceGroups: ndpResourceGroups,
        ndpResourceFiles: ndpResourceFiles
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.setProperties(controller, model);
    },
    _reload: function _reload(promise) {
      promise.then(function (collection) {
        collection.reload();
      });
    },
    actions: {
      reload: function reload() {
        return this.model();
      }
    }
  });

  _exports.default = _default;
});
define("oversight/pods/components/remove-loading/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('.app-loading').remove();
    }
  });

  _exports.default = _default;
});
define("oversight/pods/versions/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _requireNode = requireNode('electron'),
      shell = _requireNode.shell;

  var _default = Ember.Controller.extend({
    actions: {
      download: function download(event) {
        event.preventDefault();
        shell.openExternal(event.target.href);
      }
    }
  });

  _exports.default = _default;
});
define("oversight/pods/components/timeline-event-project-diary-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y1JRQqLh",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"ric-form\",null,[[\"model\",\"onSubmit\",\"onCancel\"],[[24,[\"changeset\"]],[28,\"action\",[[23,0,[]],\"submit\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"form\",\"element\"]],\"expected `f.form.element` to be a contextual component but found a string. Did you mean `(component f.form.element)`? ('oversight/pods/components/timeline-event-project-diary-form/template.hbs' @ L5:C6) \"],null]],[[\"controlType\",\"property\"],[\"textarea\",\"body\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[23,1,[\"actions\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/timeline-event-project-diary-form/template.hbs"
    }
  });

  _exports.default = _default;
});
define("oversight/pods/projects/show/data/experiments/show/studies/show/series/show/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: {},
    _routeKey: 'series.show',
    model: function model(_ref) {
      var series_id = _ref.series_id;

      var _this$modelFor = this.modelFor('projects.show.data.experiments.show.studies.show'),
          breadCrumbs = _this$modelFor.breadCrumbs;

      var series = this.store.findRecord('series', series_id);
      return Ember.RSVP.hash({
        breadCrumbs: breadCrumbs,
        series: series
      });
    },
    afterModel: function afterModel(model) {
      var breadCrumbs = model.breadCrumbs,
          series = model.series;
      var breadCrumb = this.breadCrumb;
      var name = Ember.get(series, 'seriesId');
      Ember.set(model, 'breadCrumbs', breadCrumbs.concat(name));
      Ember.set(breadCrumb, 'title', name);
    }
  });

  _exports.default = _default;
});
define("oversight/pods/projects/show/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1TnhefEA",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"project-details\",null,[[\"project\",\"compact\"],[[24,[\"project\"]],true]]],false],[0,\"\\n\\n\"],[1,[28,\"timeline-events\",null,[[\"project\",\"timelineEvents\",\"onCreate\",\"onDelete\"],[[24,[\"project\"]],[28,\"sort-by\",[\"createdAt:desc\",[24,[\"timelineEvents\"]]],null],[28,\"route-action\",[\"createComment\"],null],[28,\"route-action\",[\"deleteComment\"],null]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/projects/show/dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});
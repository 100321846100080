define("oversight/helpers/append-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    currentSession: Ember.inject.service(),
    compute: function compute(params) {
      var url = params[0];
      var token = Ember.get(this, 'currentSession.token');
      return "".concat(url, "?token=").concat(token);
    },
    tokenDidChange: Ember.observer('currentSession.token', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});
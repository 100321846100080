define("oversight/pods/components/add-named-entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IeXs225z",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[7,\"a\",false],[12,\"class\",\"trigger\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"activate\"]],[8],[0,\"...\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isActive\"]]],null,{\"statements\":[[4,\"ric-dialog\",null,[[\"onClose\",\"class\"],[[28,\"action\",[[23,0,[]],\"cancel\"],null],\"dialog\"]],{\"statements\":[[4,\"ric-form\",null,[[\"model\",\"fullScreen\",\"onSubmit\",\"onCancel\"],[[24,[\"changeset\"]],false,[28,\"action\",[[23,0,[]],\"submit\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"fieldset\"]],\"expected `f.fieldset` to be a contextual component but found a string. Did you mean `(component f.fieldset)`? ('oversight/pods/components/add-named-entry/template.hbs' @ L9:C9) \"],null]],null,{\"statements\":[[0,\"        \"],[7,\"legend\",true],[8],[0,\"Add new \"],[1,[22,\"name\"],false],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n          \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"form\",\"element\"]],\"expected `f.form.element` to be a contextual component but found a string. Did you mean `(component f.form.element)`? ('oversight/pods/components/add-named-entry/template.hbs' @ L13:C12) \"],null]],[[\"controlType\",\"label\",\"placeholder\",\"property\",\"class\"],[\"text\",\"Name\",\"Name\",\"name\",\"col-12\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[1,[23,1,[\"actions\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/add-named-entry/template.hbs"
    }
  });

  _exports.default = _default;
});
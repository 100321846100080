define("oversight/pods/components/login-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['layout-row', 'layout-align-center-center'],
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    valid: Ember.computed.and('username', 'password'),
    notValid: Ember.computed.not('valid'),
    submitting: false,
    didInsertElement: function didInsertElement() {
      this.$('.login-form-username input').focus();
    },
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = Ember.getProperties(this, 'username', 'password');
        var authenticator = 'authenticator:knock';
        Ember.set(this, 'submitting', true);
        this.session.authenticate(authenticator, {
          auth: credentials
        }).catch(function (error) {
          Ember.set(_this, 'submitting', false);

          if (error === undefined) {
            _this.flashMessages.error('Failed to connect to server. Make sure you are connected to the internet.');
          } else if (error.status >= 500) {
            _this.flashMessages.error('Whoops, looks like something broke on our end. We will take a look at the issue shortly.');
          } else {
            _this.flashMessages.error('Failed to authenticate');
          }
        });
      }
    }
  });

  _exports.default = _default;
});
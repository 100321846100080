define("oversight/pods/projects/index/controller", ["exports", "ember-ric-utils/mixins/sort-by"], function (_exports, _sortBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUS_OPTIONS = ['My Projects', 'Submitted', 'Active', 'Inactive', ''];

  var _default = Ember.Controller.extend(_sortBy.default, {
    STATUS_OPTIONS: STATUS_OPTIONS,
    queryParams: ['status', 'search'],
    sortProperty: 'screenId',
    sortAscending: false,
    status: 'My Projects',
    showInfo: false,
    isLoading: Ember.computed.alias('model.projects.isRunning'),
    projects: Ember.computed.alias('model.task.lastSuccessful.value'),
    projectsWithStatus: Ember.computed('projects.@each.status', 'status', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'projects', 'status'),
          projects = _EmberGetProperties.projects,
          status = _EmberGetProperties.status;

      if (Ember.isBlank(projects)) {
        return [];
      }

      if (Ember.isBlank(status)) {
        return projects;
      }

      var selectedStatus = [status];
      return projects.filter(function (project) {
        return selectedStatus.includes(Ember.get(project, 'status'));
      });
    }),
    sortedRecords: Ember.computed.sort('projects', 'sortOrder'),
    filteredRecords: Ember.computed.alias('sortedRecords'),
    actions: {
      toggleShowInfo: function toggleShowInfo() {
        this.toggleProperty('showInfo');
      }
    }
  });

  _exports.default = _default;
});
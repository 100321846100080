define("oversight/models/version", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    version: attr('string'),
    url: attr('string'),
    winX64Url: attr('string'),
    winX32Url: attr('string'),
    versionNumber: Ember.computed('version', function () {
      return Number(this.version.replace(/\./, ''));
    })
  });

  _exports.default = _default;
});
define("oversight/helpers/currency-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currencyFormat = currencyFormat;
  _exports.default = void 0;

  function currencyFormat(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.helper(currencyFormat);

  _exports.default = _default;
});
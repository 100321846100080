define("oversight/pods/components/links-with-follower/component", ["exports", "ember-links-with-follower/components/links-with-follower", "oversight/pods/components/links-with-follower/template"], function (_exports, _linksWithFollower, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _linksWithFollower.default.extend({
    layout: _template.default
  });

  _exports.default = _default;
});
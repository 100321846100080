define("oversight/pods/components/project-users/component", ["exports", "ember-ric-utils/mixins/search-term", "ember-ric-utils/mixins/filtered-records", "ember-ric-utils/mixins/sort-by", "oversight/mixins/namespace"], function (_exports, _searchTerm, _filteredRecords, _sortBy, _namespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_searchTerm.default, _filteredRecords.default, _sortBy.default, _namespace.default, {
    project: null,
    projectUsers: null,
    onUpdate: function onUpdate() {},
    sortProperty: 'name',
    sortAscending: true,
    filterOn: 'name',
    sortedRecords: Ember.computed.sort('projectUsers', 'sortOrder')
  });

  _exports.default = _default;
});
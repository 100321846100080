define("oversight/models/ndp-resource-group", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-data"], function (_exports, _slicedToArray2, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    ndpResourceGroup: belongsTo('ndp-resource-group', {
      inverse: 'ndpResourceGroups'
    }),
    ndpResourceGroups: hasMany('ndp-resource-group', {
      inverse: 'ndpResourceGroup'
    }),
    ndpResourceFiles: hasMany('ndp-resource-file', {
      inverse: 'ndpResourceGroup'
    }),
    name: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    // Folder interface
    isDirectory: true,
    badge: 'ndp',
    children: Ember.computed('ndpResourceGroups.content.length', 'ndpResourceFiles.content.length', function () {
      var ndpResourceGroups = Ember.get(this, 'ndpResourceGroups');
      var ndpResourceFiles = Ember.get(this, 'ndpResourceFiles');
      return Ember.RSVP.all([ndpResourceGroups, ndpResourceFiles]).then(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
            ndpResourceGroups = _ref2[0],
            ndpResourceFiles = _ref2[1];

        return ndpResourceGroups.toArray().concat(ndpResourceFiles.toArray());
      });
    }),
    segments: Ember.computed('id', function () {
      var id = Ember.get(this, 'id');
      return id.split('%2F');
    }).volatile()
  });

  _exports.default = _default;
});
define("oversight/models/site-ldap-user", ["exports", "oversight/models/site-user"], function (_exports, _siteUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _siteUser.default.extend({});

  _exports.default = _default;
});
define("oversight/pods/projects/show/data/experiments/show/studies/show/index/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: {},
    _routeKey: 'studies.show',
    model: function model() {
      var _this$modelFor = this.modelFor('projects.show.data.experiments.show.studies.show'),
          breadCrumbs = _this$modelFor.breadCrumbs,
          study = _this$modelFor.study;

      var series = Ember.get(study, 'series');
      series.then(function (collection) {
        collection.reload();
      });
      return Ember.RSVP.hash({
        breadCrumbs: breadCrumbs,
        study: study,
        series: series
      });
    },
    afterModel: function afterModel(_ref) {
      var study = _ref.study;
      var breadCrumb = this.breadCrumb;
      Ember.set(breadCrumb, 'title', Ember.get(study, 'name'));
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.setProperties(controller, model);
    }
  });

  _exports.default = _default;
});
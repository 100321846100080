define("oversight/pods/components/login-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RFScUqnL",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"onSubmit\"],[[23,0,[]],[28,\"action\",[[23,0,[]],\"authenticate\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-header\"],[8],[0,\"\\n      Login\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('oversight/pods/components/login-form/template.hbs' @ L7:C8) \"],null]],[[\"controlType\",\"label\",\"placeholder\",\"property\",\"class\"],[\"text\",\"Partners ID\",\"Your Partners ID\",\"username\",\"login-form-username\"]]],false],[0,\"\\n      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('oversight/pods/components/login-form/template.hbs' @ L8:C8) \"],null]],[[\"controlType\",\"label\",\"placeholder\",\"property\"],[\"password\",\"Password\",\"Your Partners Password\",\"password\"]]],false],[0,\"\\n      \"],[1,[28,\"bs-button\",null,[[\"defaultText\",\"type\",\"buttonType\",\"disabled\"],[\"Submit\",\"primary\",\"submit\",[28,\"or\",[[24,[\"notValid\"]],[24,[\"submitting\"]]],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/login-form/template.hbs"
    }
  });

  _exports.default = _default;
});
define("oversight/instance-initializers/session-timeout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // import Ember from 'ember';
  // const { run, testing } = Ember;
  function initialize()
  /*appInstance*/
  {//  const currentSession = appInstance.lookup('service:current-session');
    //
    //  const invalidateSession = function() {
    //    if (this.get('isAuthenticated')) {
    //      this.invalidate();
    //    }
    //  };
    //
    //  const resetSessionTimeout = function() {
    //    const { session, expiresIn } = currentSession.getProperties('session', 'expiresIn');
    //
    //    run.debounce(session, invalidateSession, expiresIn);
    //  };
    //
    //  const scheduleResetSessionTimeout = function() {
    //    run.debounce(this, resetSessionTimeout, 5000);
    //  };
    //
    //  if (!testing) {
    //    window.onclick    = scheduleResetSessionTimeout;
    //    window.onkeypress = scheduleResetSessionTimeout;
    //  }
  }

  var _default = {
    name: 'session-timeout',
    initialize: initialize
  };
  _exports.default = _default;
});
define("oversight/pods/components/upload-file-manager/component", ["exports", "ember-ric-utils/mixins/sort-by", "ember-ric-utils/mixins/search-term"], function (_exports, _sortBy, _searchTerm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortBy.default, _searchTerm.default, {
    classNameBindings: ['active::hidden'],
    upload: Ember.inject.service(),
    sortProperty: 'item.file.name',
    sortAscending: true,
    active: false,
    isProcessing: false,
    actionQueue: Ember.computed.alias('upload.actionQueue'),
    queue: Ember.computed.alias('upload.queue'),
    sortedRecords: Ember.computed.sort('actionQueue', 'sortOrder'),
    filteredRecords: Ember.computed('sortedRecords', 'searchTerm', function () {
      var records = this.sortedRecords;
      var search = this.searchTerm;

      if (Ember.isBlank(search)) {
        return records;
      }

      var term = search.replace(/[\\/$^.]/g, '');
      var filter = new RegExp(term, 'i');
      return records.filter(function (record) {
        return filter.test(Ember.get(record, 'item.file.name'));
      });
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var upload = this.upload;
      upload.on('fileCheckStarted', function () {
        if (_this.isDestroyed) {
          return;
        }

        _this.set('active', true);

        _this.set('isProcessing', true);
      });
      upload.on('fileCheckComplete', function () {
        if (_this.isDestroyed) {
          return;
        }

        _this.set('isProcessing', false);
      });
    },
    actions: {
      update: function update(record, action) {
        Ember.set(record, 'action', action);
      },
      updateAll: function updateAll(action) {
        this.actionQueue.forEach(function (record) {
          Ember.set(record, 'action', action);
        });
      },
      done: function done() {
        var queue = this.queue;
        var actionQueue = this.actionQueue;
        var upload = this.upload;
        actionQueue.forEach(function (record) {
          if (record.action === "Replace") {
            queue.pushObject(record.item);
          }
        });
        actionQueue.clear();
        upload.startUpload();
        this.set('active', false);
      }
    }
  });

  _exports.default = _default;
});
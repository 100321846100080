define("oversight/pods/components/upload-manager/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['minimized', 'active::inactive'],
    upload: Ember.inject.service(),
    minimized: false,
    active: false,
    paused: false,
    queue: Ember.computed.alias('upload.queue'),
    queueLength: Ember.computed.alias('upload.queue.length'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var upload = this.upload;
      upload.on('startUpload', function () {
        Ember.set(_this, 'active', true);
      });
    },
    actions: {
      minimize: function minimize() {
        this.toggleProperty('minimized');
      },
      close: function close() {
        Ember.set(this, 'active', false);
      },
      retry: function retry(item) {
        Ember.set(item, 'failed', false);
        item.resumable.retry();
      },
      pause: function pause(resumableFile) {
        Ember.set(this, 'paused', true);
        resumableFile.resumableObj.pause();
      },
      resume: function resume(resumableFile) {
        Ember.set(this, 'paused', false);
        resumableFile.resumableObj.upload();
      }
    }
  });

  _exports.default = _default;
});
define("oversight/pods/components/timeline-event/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['modelName'],
    flashMessages: Ember.inject.service(),
    empty: Ember.computed.empty('event'),
    modelName: Ember.computed('event', function () {
      var event = Ember.get(this, 'event');

      if (event === undefined) {
        return 'empty';
      }

      return Ember.get(event, 'modelName');
    }),
    iconName: Ember.computed('modelName', function () {
      var modelName = Ember.get(this, 'modelName');

      switch (modelName) {
        case 'project-diary':
          return 'comment';

        case 'data-change':
          return 'hdd-o';

        case 'resource-change':
          return 'hdd-o';

        case 'user-change':
          return 'users';

        case 'study-change':
          return 'file-image-o';

        default:
          return 'plus';
      }
    }),
    click: function click() {
      var action = Ember.get(this, 'onClick');

      if (action) {
        action();
      }
    },
    actions: {}
  });

  _exports.default = _default;
});
define("oversight/pods/projects/show/data/experiments/show/studies/show/series/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2UdIHPhw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ric-dialog\",null,[[\"onClose\",\"class\"],[[28,\"action\",[[23,0,[]],\"closePreview\"],null],\"dicom-preview--wrapper\"]],{\"statements\":[[0,\"  \"],[1,[22,\"bread-crumbs\"],false],[0,\"\\n\\n  \"],[1,[28,\"dicom-preview\",null,[[\"images\"],[[24,[\"images\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/projects/show/data/experiments/show/studies/show/series/show/index/template.hbs"
    }
  });

  _exports.default = _default;
});
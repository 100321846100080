define("oversight/pods/versions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FjW6NPYi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[8],[0,\"Update\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"Download latest version (\"],[1,[24,[\"model\",\"version\"]],false],[0,\") for your OS\"],[9],[0,\"\\n\\n\"],[7,\"ul\",true],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"download\"],null]],[11,\"href\",[24,[\"model\",\"url\"]]],[8],[0,\"MacOS\"],[9],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"download\"],null]],[11,\"href\",[24,[\"model\",\"winX64Url\"]]],[8],[0,\"Windows (64bit)\"],[9],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"download\"],null]],[11,\"href\",[24,[\"model\",\"winX32Url\"]]],[8],[0,\"Windows (32bit)\"],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/versions/template.hbs"
    }
  });

  _exports.default = _default;
});
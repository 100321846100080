define("oversight/utils/functions", ["exports", "ember-string-helpers/utils/functions"], function (_exports, _functions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _functions.default;
    }
  });
});
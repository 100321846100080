define("oversight/helpers/progress-styles", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.progressStyles = progressStyles;
  _exports.default = void 0;

  function progressStyles(params
  /*, hash*/
  ) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        totalItems = _params[0],
        remainingItems = _params[1];

    var completedItems = totalItems - remainingItems;
    var percentComplete = Math.ceil(completedItems / totalItems * 100);

    if (isNaN(percentComplete)) {
      percentComplete = 0;
    }

    return Ember.String.htmlSafe("width: ".concat(percentComplete, "%"));
  }

  var _default = Ember.Helper.helper(progressStyles);

  _exports.default = _default;
});
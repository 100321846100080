define("oversight/utils/parse-query-data", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var parseSortData = function parseSortData(sortData) {
    sortData = sortData || [];
    var sort;

    if (sortData[0]) {
      sort = sortData[0].prop;

      if (sortData[0].direction === 'desc') {
        sort = sort.split(',').map(function (part) {
          return "-".concat(part);
        }).join(',');
      }
    }

    return sort;
  };

  var parseFilterData = function parseFilterData(filterData) {
    filterData = filterData || {};
    var search = filterData.filter;
    var filter = {
      search: search
    };
    filterData.columnFilters.forEach(function (option) {
      if (option.filter !== undefined) {
        filter[option.filterUsing] = option.filter;
      }

      if ((0, _typeof2.default)(option.filterUsing) == "object") {
        Ember.assign(filter, option.filterUsing);
      }
    });
    return filter;
  };

  var parsePaginationData = function parsePaginationData(paginationData) {
    paginationData = paginationData || {
      pageNumber: 1,
      pageSize: 1000
    };
    var _paginationData = paginationData,
        pageNumber = _paginationData.pageNumber,
        pageSize = _paginationData.pageSize;
    return {
      number: pageNumber,
      size: pageSize
    };
  };

  var parseQueryData = function parseQueryData(_ref) {
    var paginationData = _ref.paginationData,
        sortData = _ref.sortData,
        filterData = _ref.filterData;
    var sort = parseSortData(sortData);
    var filter = parseFilterData(filterData);
    var page = parsePaginationData(paginationData);
    return {
      sort: sort,
      filter: filter,
      page: page
    };
  };

  var _default = parseQueryData;
  _exports.default = _default;
});
define("oversight/models/experiment", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-data"], function (_exports, _slicedToArray2, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    project: belongsTo('project'),
    pi: belongsTo('site-user'),
    patients: hasMany('patient'),
    studies: hasMany('study'),
    dataResourceGroups: hasMany('data-resource-group'),
    dataResourceFiles: hasMany('data-resource-file'),
    title: attr('string'),
    startDate: attr('date'),
    description: attr('string'),
    numberOfStudies: attr('number'),
    numberOfSeries: attr('number'),
    numberOfImages: attr('number'),
    numberOfNondcmStudies: attr('number'),
    numberOfFiles: attr('number'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    // Folder interface
    name: Ember.computed.alias('title'),
    parent: Ember.computed.alias('project'),
    children: Ember.computed('studies', 'dataResourceGroups', 'dataResourceFiles', function () {
      var studies = Ember.get(this, 'studies');
      var dataResourceGroups = Ember.get(this, 'dataResourceGroups');
      var dataResourceFiles = Ember.get(this, 'dataResourceFiles');
      return Ember.RSVP.all([studies, dataResourceGroups, dataResourceFiles]).then(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
            studies = _ref2[0],
            dataResourceGroups = _ref2[1],
            dataResourceFiles = _ref2[2];

        return studies.toArray().concat(dataResourceGroups.toArray()).concat(dataResourceFiles.toArray());
      });
    }),
    isDirectory: true,
    isExperiment: true,
    path: Ember.computed('id', function () {
      return "experiments/".concat(this.id);
    }),
    badge: Ember.computed('{numberOfStudies,numberOfNondcmStudies}', function () {
      if (Ember.get(this, 'numberOfStudies') > 0) {
        return 'dcm';
      }

      return 'exp';
    }),
    totalSessions: Ember.computed('{numberOfStudies,numberOfNondcmStudies}', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'numberOfStudies', 'numberOfNondcmStudies'),
          numberOfStudies = _EmberGetProperties.numberOfStudies,
          numberOfNondcmStudies = _EmberGetProperties.numberOfNondcmStudies;

      return numberOfStudies + numberOfNondcmStudies;
    }),
    totalImages: Ember.computed('{numberOfImages,numberOfFiles}', function () {
      var _EmberGetProperties2 = Ember.getProperties(this, 'numberOfImages', 'numberOfFiles'),
          numberOfImages = _EmberGetProperties2.numberOfImages,
          numberOfFiles = _EmberGetProperties2.numberOfFiles;

      return numberOfImages + numberOfFiles;
    })
  });

  _exports.default = _default;
});
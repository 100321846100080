define("oversight/helpers/format-phone", ["exports", "ember-string-helpers/helpers/format-phone"], function (_exports, _formatPhone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formatPhone.default;
    }
  });
  Object.defineProperty(_exports, "formatPhone", {
    enumerable: true,
    get: function get() {
      return _formatPhone.formatPhone;
    }
  });
});
define("oversight/mixins/remove-data-resource", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      removeDataResource: function removeDataResource(record) {
        var _this = this;

        var messages = this.flashMessages;
        var store = this.store;
        return record.destroyRecord().then(function () {
          messages.success("'".concat(Ember.get(record, 'name'), "' has been deleted"));
          store.unloadRecord(record);
          Ember.run.next(_this, function () {
            record._internalModel.destroySync();
          });
        }).catch(function () {
          messages.error("Failed to delete '".concat(Ember.get(record, 'name'), "'"));
        });
      }
    }
  });

  _exports.default = _default;
});
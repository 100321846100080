define("oversight/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "oversight/config/environment", "ember-ric-utils/mixins/masquerading"], function (_exports, _applicationRouteMixin, _environment, _masquerading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Pusher from 'oversight/mixins/pusher';
  var Logger = Ember.Logger;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _masquerading.default, {
    ENV: _environment.default,
    currentSession: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    raven: Ember.inject.service(),
    routeAfterAuthentication: 'projects',
    setupController: function setupController() {
      this._super.apply(this, arguments);

      this._setupPusher();
    },
    _setupPusher: function _setupPusher() {
      var key = (_environment.default['pusher'] || {}).key;

      if (!Ember.testing && Ember.isPresent(key)) {
        this.pusher.setup(key, {});
      }
    },
    invalidateSession: function invalidateSession() {
      Ember.get(this, 'raven').callRaven("setUserContext");
      Ember.get(this, 'raven').callRaven("setExtraContext");
      var session = Ember.get(this, 'session');

      if (Ember.get(session, 'isAuthenticated')) {
        return session.invalidate();
      } else {
        return new Promise(function (resolve) {
          resolve();
        });
      }
    },
    actions: {
      invalidateSession: function invalidateSession() {
        var _this = this;

        this.invalidateSession().then(function () {
          _this.store.unloadAll();

          _this.transitionTo('login');
        });
      },
      error: function error(_error) {
        var redirectUrl = Ember.getWithDefault(this, 'errorRedirectUrl', 'index');
        var messages = Ember.get(this, 'flashMessages');
        var raven = Ember.get(this, 'raven');
        var currentSession = Ember.getWithDefault(this, 'currentSession', {});
        var sessionData = Ember.getWithDefault(currentSession, 'data', {});
        var userContext = Ember.getProperties(sessionData, 'name', 'email', 'id');
        var context = Ember.getProperties(currentSession, 'isAuthenticated', 'isMasquerading', 'adminUsername', 'expiresIn');

        if (_error.isAdapterError) {
          var responseError = _error.errors[0];

          if (responseError) {
            if (window.navigator.onLine) {
              switch (responseError.status) {
                case "404":
                  messages.error("The requested record could not be found or you're not authorized to access it");
                  break;

                case "401":
                  messages.error("You are not authorized for this request");
                  break;

                default:
                  messages.error("We were unable to complete the request. We have been informed of the issue and will take a look shortly.");
                  break;
              }
            } else {
              messages.error("Looks like you are currently not connected.");
            }
          } else {
            messages.error("Something went wrong. We have been informed of the issue and will take a look shortly.");
          }

          try {
            raven.callRaven("setUserContext", userContext);
            raven.callRaven("setExtraContext", context);
            raven.captureException(_error);
            raven.callRaven("showReportDialog");
          } catch (e) {
            Logger.error(e);
          }

          this.transitionTo(redirectUrl);
        } else {
          messages.error("Something went wrong. We have been informed of the issue and will take a look shortly.");

          try {
            raven.callRaven("setUserContext", userContext);
            raven.callRaven("setExtraContext", context);
            raven.captureException(_error); // raven.callRaven("showReportDialog");
          } catch (e) {
            Logger.error(e);
          }
        }
      }
    }
  });

  _exports.default = _default;
});
define("oversight/pods/authorized/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-ric-utils/mixins/route-authorization", "oversight/config/environment"], function (_exports, _authenticatedRouteMixin, _routeAuthorization, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var namespace = _environment.default.namespace;

  var _default = Ember.Route.extend(_routeAuthorization.default, _authenticatedRouteMixin.default, {
    routeKey: Ember.computed('_routeKey', function () {
      return "".concat(namespace, ".").concat(this._routeKey);
    }),
    _routeKey: Ember.computed.alias('routeName')
  });

  _exports.default = _default;
});
define("oversight/validations/create-site-user", ["exports", "site-ui/validations/create-site-user"], function (_exports, _createSiteUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _createSiteUser.default;
    }
  });
});
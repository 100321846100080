define("oversight/models/patient", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    experiment: belongsTo('experiment'),
    studies: hasMany('study'),
    patName: attr('string'),
    patMrn: attr('string'),
    patDob: attr('date'),
    patSex: attr('string'),
    patPath: attr('string'),
    modTime: attr('string'),
    anonymFlag: attr('string'),
    // Folder Interface
    name: Ember.computed.alias('patName'),
    parent: Ember.computed.alias('experiment'),
    children: Ember.computed.alias('studies'),
    isDirectory: true
  });

  _exports.default = _default;
});
define("oversight/mixins/namespace", ["exports", "oversight/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var namespace = _environment.default.namespace;

  var _default = Ember.Mixin.create({
    namespace: namespace
  });

  _exports.default = _default;
});
define("oversight/models/site-menu", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import computed from 'ember-computed-decorators';
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    section: belongsTo('site-section'),
    groups: hasMany('site-group'),
    permissions: hasMany('site-permission'),
    priority: attr('number'),
    name: attr('string'),
    block: attr('string'),
    url: attr('string'),
    protected: attr('boolean'),
    visible: attr('boolean'),
    reference: attr('boolean'),
    disambiguate: attr('boolean'),
    sectionName: Ember.computed.alias('section.name')
  });

  _exports.default = _default;
});
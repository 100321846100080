define("oversight/pods/projects/show/data/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {},
    _routeKey: 'projects.show',
    model: function model() {
      var project = this.modelFor('projects.show');
      var breadCrumbs = [Ember.get(project, 'screenId')];
      return Ember.RSVP.hash({
        breadCrumbs: breadCrumbs,
        project: project
      });
    },
    afterModel: function afterModel(_ref) {
      var project = _ref.project;
      var breadCrumb = this.breadCrumb;
      Ember.set(breadCrumb, 'title', Ember.get(project, 'screenId'));
    }
  });

  _exports.default = _default;
});
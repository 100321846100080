define("oversight/pods/components/timeline-event-project-diary-form/component", ["exports", "ember-changeset"], function (_exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.model = this.store.createRecord('project-diary');
      this.changeset = new _emberChangeset.default(this.model);
    },
    actions: {
      submit: function submit() {
        var _this = this;

        var changeset = this.changeset;
        var action = this.onSubmit;
        action(changeset).then(function () {
          var model = _this.store.createRecord('project-diary');

          changeset = new _emberChangeset.default(model);
          Ember.setProperties(_this, {
            model: model,
            changeset: changeset
          });

          _this.flashMessages.success('Your comment has been added');
        }).catch(function () {
          _this.flashMessages.error('Failed to add comment');
        });
      },
      cancel: function cancel() {
        var changeset = this.changeset;
        changeset.rollback();
      }
    }
  });

  _exports.default = _default;
});
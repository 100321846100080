define("oversight/helpers/is-able", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    behavior: Ember.inject.service(),
    compute: function compute(_, _ref) {
      var route = _ref.route,
          possible = _ref.possible;
      var behavior = this.behavior;
      return possible && behavior.isAble(route);
    },
    behaviorDidChange: Ember.observer('behavior.behavior', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});
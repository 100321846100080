define("oversight/helpers/route-task", ["exports", "ember-route-task-helper/helpers/route-task"], function (_exports, _routeTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _routeTask.default;
    }
  });
  Object.defineProperty(_exports, "routeTask", {
    enumerable: true,
    get: function get() {
      return _routeTask.routeTask;
    }
  });
});
define("oversight/mixins/pusher", ["exports", "ember-pusher"], function (_exports, _emberPusher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_emberPusher.default.Bindings, {
    pusher: Ember.inject.service(),
    pusherChannel: 'updates',
    pusherEvents: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.pusherEvents = ['update'];
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);

      this._wirePusher();
    },
    _wirePusher: function _wirePusher() {
      var _EmberGetProperties = Ember.getProperties(this, 'pusher', 'pusherChannel', 'pusherEvents'),
          pusher = _EmberGetProperties.pusher,
          pusherChannel = _EmberGetProperties.pusherChannel,
          pusherEvents = _EmberGetProperties.pusherEvents;

      if (!Ember.testing) {
        pusher.wire(this, pusherChannel, pusherEvents);
      }
    },
    actions: {
      update: function update(payload) {
        Ember.get(this, 'store').findRecord(payload.type, payload.id).then(function (record) {
          if (record.relationshipFor('problem')) {
            Ember.get(record, 'problem').then(function (problem) {
              Ember.get(problem, 'timelineEvents').then(function (events) {
                events.pushObject(record);
              });
            });
          }
        }).catch(function () {// noop
        });
      }
    }
  });

  _exports.default = _default;
});
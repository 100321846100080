define("oversight/pods/projects/show/data/ndp-resource-groups/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: null,
    _routeKey: 'projects.show'
  });

  _exports.default = _default;
});
define("oversight/pods/components/links-with-follower/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EZlLeomf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"html-tag\",null,[[\"tagName\",\"class\"],[[24,[\"containerTagName\"]],\"link-follower-parent\"]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\\n\"],[4,\"html-tag\",null,[[\"tagName\",\"class\"],[[24,[\"linkTagName\"]],\"link-follower\"]],{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"arrow\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/links-with-follower/template.hbs"
    }
  });

  _exports.default = _default;
});
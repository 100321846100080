define("oversight/instance-initializers/ember-preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* appInstance */
  {// appInstance.inject('route', 'foo', 'service:foo');
  }

  var _default = {
    name: 'ember-preferences',
    initialize: initialize
  };
  _exports.default = _default;
});
define("oversight/pods/components/timeline-events/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    timelineEvents: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.timelineEvents = this.timelineEvents || [];
    }
  });

  _exports.default = _default;
});
define("oversight/pods/components/timeline-event-user-change/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pPWM/1Z3",
    "block": "{\"symbols\":[\"user\",\"user\"],\"statements\":[[7,\"h5\",true],[8],[0,\"\\n  \"],[1,[24,[\"event\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"\\n  \"],[1,[24,[\"event\",\"content\",\"users\",\"length\"]],false],[0,\"\\n  \"],[4,\"if\",[[28,\"gt\",[[24,[\"event\",\"content\",\"users\",\"length\"]],1],null]],null,{\"statements\":[[0,\"users were\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"if\",[[28,\"eq\",[[24,[\"event\",\"content\",\"users\",\"length\"]],1],null]],null,{\"statements\":[[0,\"user was\"]],\"parameters\":[]},null],[0,\"\\n  added\\n\"],[9],[0,\"\\n\\n\"],[7,\"ul\",true],[10,\"class\",\"list-unstyled\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"slice\",[0,10,[24,[\"event\",\"content\",\"users\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[\"projects.show.team\",[24,[\"event\",\"content\",\"project\"]]]],{\"statements\":[[1,[23,2,[]],false]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"event\",\"content\",\"users\",\"length\"]],10],null]],null,{\"statements\":[[4,\"if\",[[24,[\"showAll\"]]],null,{\"statements\":[[4,\"each\",[[28,\"slice\",[10,[24,[\"event\",\"content\",\"users\",\"length\"]],[24,[\"event\",\"content\",\"users\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[\"projects.show.team\",[24,[\"event\",\"content\",\"project\"]]]],{\"statements\":[[1,[23,1,[]],false]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[7,\"a\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"showAll\"]],[8],[0,\"...\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/timeline-event-user-change/template.hbs"
    }
  });

  _exports.default = _default;
});
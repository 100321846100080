define("oversight/models/site-section", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    menus: hasMany('site-menu'),
    name: attr('string'),
    priority: attr('number'),
    topMenu: attr('boolean')
  });

  _exports.default = _default;
});
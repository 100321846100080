define("oversight/models/timeline-event", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    project: belongsTo('project'),
    person: belongsTo('site-user'),
    name: attr('string'),
    kind: attr('string'),
    content: attr(),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    dateInWords: Ember.computed('createdAt', function () {
      var createdAt = Ember.get(this, 'createdAt');
      return (0, _moment.default)(createdAt).format('ll');
    }),
    modelName: Ember.computed('{kind,_internalModel.modelName}', function () {
      var kind = Ember.get(this, 'kind');
      var modelName = Ember.get(this, '_internalModel.modelName');
      return kind || modelName;
    })
  });

  _exports.default = _default;
});
define("oversight/models/project-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    project: belongsTo('project'),
    user: belongsTo('site-user'),
    name: attr('string'),
    permissions: attr('string'),
    read: Ember.computed('permissions', {
      get: function get() {
        return this._hasPermission('R');
      },
      set: function set(_, value) {
        this._setPermission('R', value);

        return this._hasPermission('R');
      }
    }),
    write: Ember.computed('permissions', {
      get: function get() {
        return this._hasPermission('W');
      },
      set: function set(_, value) {
        this._setPermission('W', value);

        return this._hasPermission('W');
      }
    }),
    delete: Ember.computed('permissions', {
      get: function get() {
        return this._hasPermission('D');
      },
      set: function set(_, value) {
        this._setPermission('D', value);

        return this._hasPermission('D');
      }
    }),
    _hasPermission: function _hasPermission(permission) {
      return (Ember.get(this, 'permissions') || '').indexOf(permission) >= 0;
    },
    _setPermission: function _setPermission(permission, value) {
      var permissions = Ember.get(this, 'permissions') || '';
      var newPermissions = Ember.A(permissions.split(''));

      if (value) {
        newPermissions.addObject(permission);
      } else {
        newPermissions.removeObject(permission);
      }

      newPermissions = newPermissions.sort().join('');
      Ember.set(this, 'permissions', newPermissions);
    }
  });

  _exports.default = _default;
});
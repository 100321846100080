define("oversight/helpers/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resources = resources;
  _exports.default = void 0;

  function resources(params
  /*, hash*/
  ) {
    return params[0].get('resources');
  }

  var _default = Ember.Helper.helper(resources);

  _exports.default = _default;
});
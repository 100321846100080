define("oversight/pods/components/timeline-event-data-change/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showAll: false,
    actions: {
      showAll: function showAll() {
        this.toggleProperty('showAll');
      }
    }
  });

  _exports.default = _default;
});
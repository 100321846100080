define("oversight/services/behavior", ["exports", "oversight/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var canIndex = new RegExp(/[ra]/);
  var canShow = new RegExp(/[ra]/);
  var canNew = new RegExp(/[ca]/);
  var canEdit = new RegExp(/[ua]/);
  var canDelete = new RegExp(/[d]/);
  var namespace = _environment.default.namespace;

  var _default = Ember.Service.extend({
    currentSession: Ember.inject.service(),
    behavior: Ember.computed('currentSession.behavior.timestamp', function () {
      var behavior = Ember.getWithDefault(this, 'currentSession.behavior', {});
      var routes = behavior;
      routes = this._extendRoutes(routes);
      routes = this._mapRoutes(routes);
      return routes;
    }),
    isAble: function isAble(routeName) {
      var behavior = Ember.get(this, 'behavior');

      if (Ember.isNone(behavior)) {
        return false;
      }

      routeName = routeName.replace(/\.index/g, '');
      routeName = routeName.replace(/\.show\.delete/, '.delete');
      routeName = routeName.replace(/\.show\.edit/, '.edit');
      var permitted = behavior[routeName];
      return !!permitted;
    },
    isUnable: function isUnable(routeName) {
      return !this.isAble(routeName);
    },
    _extendRoutes: function _extendRoutes(behavior) {
      return Object.keys(behavior).reduce(function (routes, baseRoute) {
        var privileges = behavior[baseRoute];

        if (canIndex.test(privileges)) {
          routes[baseRoute] = true;
        }

        if (canShow.test(privileges)) {
          routes["".concat(baseRoute, ".show")] = true;
        }

        if (canNew.test(privileges)) {
          routes["".concat(baseRoute, ".new")] = true;
        }

        if (canEdit.test(privileges)) {
          routes["".concat(baseRoute, ".edit")] = true;
        }

        if (canDelete.test(privileges)) {
          routes["".concat(baseRoute, ".delete")] = true;
        }

        return routes;
      }, {});
    },
    _mapRoutes: function _mapRoutes(behavior) {
      var isImaging = new RegExp(/^imaging\./);
      return Object.keys(behavior).reduce(function (routes, key) {
        if (isImaging.test(key)) {
          routes[key.replace(/^imaging/, namespace)] = behavior[key];
        } else {
          routes[key] = behavior[key];
        }

        return routes;
      }, {});
    }
  });

  _exports.default = _default;
});
define("oversight/services/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    siteUsers: {
      statusOptions: ['Employee', 'External'],
      editDisabled: true
    }
  });

  _exports.default = _default;
});
define("oversight/pods/projects/show/settings/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    _routeKey: 'projects.edit',
    model: function model() {
      var project = this.modelFor('projects.show');
      return this.store.findRecord('project', project.id, {
        include: 'all'
      });
    },
    setupController: function setupController(controller, project) {
      this._super.apply(this, arguments);

      Ember.setProperties(controller, {
        project: project
      });
    }
  });

  _exports.default = _default;
});
define("oversight/pods/components/navigation-toolbar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service('current-session'),
    menus: Ember.computed.alias('session.menus')
  });

  _exports.default = _default;
});
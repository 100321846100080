define("oversight/pods/projects/show/team/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    _routeKey: 'project-users',
    model: function model() {
      var project = this.modelFor('projects.show');
      var projectUsers = Ember.get(project, 'projectUsers');
      projectUsers.then(function (collection) {
        collection.reload();
      });
      return Ember.RSVP.hash({
        project: project,
        projectUsers: projectUsers
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.setProperties(controller, model);
    }
  });

  _exports.default = _default;
});
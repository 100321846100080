define("oversight/pods/index/route", ["exports", "oversight/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var namespace = _environment.default.namespace;

  var _default = Ember.Route.extend({
    behavior: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var behavior = this.behavior;

      if (behavior.isAble("".concat(namespace, ".projects"))) {
        this.transitionTo('projects');
      } else {
        transition.send('invalidateSession');
        this.transitionTo('login');
      }
    }
  });

  _exports.default = _default;
});
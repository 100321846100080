define("oversight/pods/application/adapter", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "ember-simple-auth-token/mixins/token-authorizer", "oversight/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _tokenAuthorizer, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  var _default = JSONAPIAdapter.extend(_dataAdapterMixin.default, _tokenAuthorizer.default, {
    host: _environment.default.APIEndpoint,
    namespace: _environment.default.APINamespace
  });

  _exports.default = _default;
});
define("oversight/models/data-resource-group", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-data"], function (_exports, _slicedToArray2, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    dataResourceGroup: belongsTo('data-resource-group', {
      inverse: 'dataResourceGroups'
    }),
    dataResourceGroups: hasMany('data-resource-group', {
      inverse: 'dataResourceGroup'
    }),
    dataResourceFiles: hasMany('data-resource-file', {
      inverse: 'dataResourceGroup'
    }),
    name: attr('string'),
    path: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    _moveTo: attr('string'),
    // Folder interface
    isDirectory: true,
    isResource: true,
    badge: 'data',
    children: Ember.computed('dataResourceGroups.content.length', 'dataResourceFiles.content.length', function () {
      var dataResourceGroups = Ember.get(this, 'dataResourceGroups');
      var dataResourceFiles = Ember.get(this, 'dataResourceFiles');
      return Ember.RSVP.all([dataResourceGroups, dataResourceFiles]).then(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
            dataResourceGroups = _ref2[0],
            dataResourceFiles = _ref2[1];

        return dataResourceGroups.toArray().concat(dataResourceFiles.toArray());
      });
    }),
    segments: Ember.computed('id', function () {
      var id = Ember.get(this, 'id');
      return id.split('/');
    }).volatile(),
    pathPrefix: Ember.computed('id', function () {
      var id = Ember.get(this, 'id');
      return id.replace(/^[a-z]+\/[0-9]+/, '');
    }).volatile()
  });

  _exports.default = _default;
});
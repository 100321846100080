define("oversight/utils/uploader", ["exports", "jquery", "oversight/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APIEndpoint = _environment.default.APIEndpoint,
      APINamespace = _environment.default.APINamespace,
      dataUpload = _environment.default.dataUpload;

  var _default = Ember.Object.extend(Ember.Evented, {
    entity: null,
    pathPrefix: null,
    token: null,
    defaultContentType: 'application/octet-stream',
    upload: function upload(file) {
      var _this = this;

      return this.process(file).then(function () {
        _this.finish();
      }).catch(function () {
        _this.didFail();
      });
    },
    process: function process(file) {
      var _this2 = this;

      var entity = this.entity;
      var id = Ember.get(entity, 'id');
      var entity_type = Ember.get(entity, '_internalModel.modelName');
      var path_prefix = this.pathPrefix;
      var url = "".concat(APIEndpoint, "/").concat(APINamespace, "/").concat(dataUpload, "/").concat(entity_type, "/").concat(id, "/").concat(path_prefix);
      var token = this.token;
      var formData = new FormData();
      formData.append('file', file);
      var settings = {
        url: url,
        type: 'POST',
        contentType: false,
        processData: false,
        data: formData,
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", "Bearer ".concat(token));
        },
        xhr: function xhr() {
          var xhr = _jquery.default.ajaxSettings.xhr();

          xhr.upload.onprogress = function (e) {
            _this2.didProgress(e);
          };

          return xhr;
        }
      };
      return this._ajax(settings);
    },
    finish: function finish() {
      this.trigger('didUpload');
    },
    didFail: function didFail() {
      this.trigger('didFail');
    },
    didProgress: function didProgress(e) {
      e.percent = e.loaded / e.total * 100;
      this.trigger('progress', e);
    },
    _ajax: function _ajax(settings) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        settings.success = function (data, status, xhr) {
          data = {
            data: data
          };
          data.xhr = xhr;
          Ember.run(null, resolve, data);
        };

        settings.error = function (xhr) {
          Ember.run(null, reject, xhr);
        };

        _jquery.default.ajax(settings);
      });
    }
  });

  _exports.default = _default;
});
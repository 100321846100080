define("oversight/pods/components/dicom-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZaCbjKVY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"tools\"],[8],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"scroll btn btn-secondary\"],[10,\"value\",\"Scroll\"],[8],[0,\"\\n    Scroll\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"zoom-pan btn btn-secondary\"],[10,\"value\",\"ZoomAndPan\"],[8],[0,\"\\n    Zoom / Pan\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"window-level btn btn-secondary\"],[10,\"value\",\"WindowLevel\"],[8],[0,\"\\n    W/L\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"layerContainer\"],[8],[0,\"\\n  \"],[7,\"canvas\",true],[10,\"class\",\"imageLayer\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/dicom-preview/template.hbs"
    }
  });

  _exports.default = _default;
});
define("oversight/pods/projects/show/dashboard/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    _routeKey: 'projects.show',
    model: function model() {
      var project = this.modelFor('projects.show');
      var timelineEvents = Ember.get(project, 'timelineEvents');
      timelineEvents.then(function (collection) {
        collection.reload();
      });
      return Ember.RSVP.hash({
        project: project,
        timelineEvents: timelineEvents
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.setProperties(controller, model);
      this.store.findRecord('project', model.project.id, {
        include: 'all'
      });
    },
    actions: {
      createComment: function createComment(changeset) {
        var project = this.modelFor('projects.show');
        Ember.set(changeset, 'project', project);
        return changeset.execute().save();
      },
      deleteComment: function deleteComment(comment) {
        return comment.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});
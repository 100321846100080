define("oversight/pods/components/reload-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JRk51a3h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[28,\"if\",[[24,[\"reload\",\"isRunning\"]],\"refresh fa-spin\",\"refresh\"],null]]]],[10,\"aria-hidden\",\"true\"],[11,\"onclick\",[28,\"perform\",[[24,[\"reload\"]]],null]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/reload-button/template.hbs"
    }
  });

  _exports.default = _default;
});
define("oversight/services/current-session", ["exports", "oversight/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var version = _environment.default.APP.version;

  var _default = Ember.Service.extend({
    version: version,
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    behavior: Ember.computed.alias('session.data.authenticated.behavior'),
    menus: Ember.computed.alias('session.data.authenticated.menus'),
    isMasquerading: Ember.computed.alias('data.masquerading'),
    adminUsername: Ember.computed.alias('data.admin'),
    expiresIn: Ember.computed('session.data.authenticated.exp', function () {
      var expiresAt = this.get('session.data.authenticated.exp');
      var now = new Date().getTime();
      return expiresAt * 1000 - now;
    }).volatile(),
    token: Ember.computed.alias('session.data.authenticated.jwt'),
    data: Ember.computed('token', function () {
      var token = this.token;

      if (Ember.isEmpty(token)) {
        return {};
      }

      var authenticator = Ember.getOwner(this).lookup('authenticator:knock');
      var data = authenticator.getTokenData(token);
      return data;
    }),
    currentUser: Ember.computed('data.id', function () {
      var data = this.data,
          store = this.store;

      if (Ember.isEmpty(data.id)) {
        return;
      }

      return store.peekRecord('site-user', data.id) || store.findRecord('site-user', data.id);
    }),
    checkVersion: Ember.computed('data.id', 'version', function () {
      var _this = this;

      var id = this.get('data.id');

      if (!id) {
        return;
      }

      var version = this.version;
      var messages = this.flashMessages;
      var versionNumber = Number(version.replace(/\./, ''));
      this.store.findRecord('version', id).then(function (currentVersion) {
        if (versionNumber < Ember.get(currentVersion, 'versionNumber')) {
          _this._newVersionAvailable(true);

          messages.warning("Your current version is outdated. Please download the latest version");
        }
      }).catch(function () {// noop
      });
      return "";
    }),
    _newVersionAvailable: function _newVersionAvailable(boolean) {
      this.set('newVersionAvailable', boolean);
    }
  });

  _exports.default = _default;
});
define("oversight/pods/projects/show/data/experiments/show/studies/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: null,
    _routeKey: 'studies',
    model: function model() {
      var experiment = this.modelFor('projects.show.data.experiments.show');
      return Ember.RSVP.hash({
        experiment: experiment
      });
    }
  });

  _exports.default = _default;
});
define("oversight/pods/components/bread-crumb/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['crumbClass'],
    crumbClass: Ember.computed.oneWay('breadCrumbs.crumbClass'),
    linkClass: Ember.computed.oneWay('breadCrumbs.linkClass'),
    hasBlock: Ember.computed.bool('template').readOnly()
  });

  _exports.default = _default;
});
define("oversight/router", ["exports", "oversight/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.url;
        var title = Ember.getWithDefault(_this, 'currentRouteName', 'unknown');
        var metrics = _this.metrics;
        metrics.trackPage({
          page: page,
          title: title
        });
      });
    }
  });
  Router.map(function () {
    this.route('login');
    this.route('logout');
    this.route('versions');
    this.route('projects', function () {
      this.route('show', {
        path: ':project_id'
      }, function () {
        this.route('dashboard');
        this.route('data', function () {
          this.route('experiments', function () {
            this.route('show', {
              path: ':experiment_id'
            }, function () {
              this.route('data-resource-groups', function () {
                this.route('show', {
                  path: ':data_resource_group_id'
                }, function () {});
              });
              this.route('studies', function () {
                this.route('show', {
                  path: ':study_id'
                }, function () {
                  this.route('series', function () {
                    this.route('show', {
                      path: ':series_id'
                    }, function () {
                      this.route('images', function () {});
                    });
                  });
                });
              });
            });
          });
          this.route('ndp-resource-groups', function () {
            this.route('show', {
              path: ':ndp_resource_group_id'
            }, function () {});
          });
          this.route('data-resource-groups', function () {
            this.route('show', {
              path: ':data_resource_group_id'
            }, function () {});
          });
        });
        this.route('team', function () {
          this.route('new');
          this.route('delete');
        });
        this.route('settings');
      });
    });
    this.mount('site');
  });
  var _default = Router;
  _exports.default = _default;
});
define("oversight/pods/projects/show/data/experiments/show/studies/show/series/show/index/controller", ["exports", "ember-ric-utils/mixins/search-term", "ember-ric-utils/mixins/filtered-records", "ember-ric-utils/mixins/sort-by"], function (_exports, _searchTerm, _filteredRecords, _sortBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_searchTerm.default, _filteredRecords.default, _sortBy.default, {
    sortProperty: 'imageNumber',
    sortAscending: true,
    sortedRecords: Ember.computed.sort('images', 'sortOrder'),
    actions: {
      closePreview: function closePreview() {
        this.transitionToRoute('projects.show.data.experiments.show.studies.show.index');
      }
    }
  });

  _exports.default = _default;
});
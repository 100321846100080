define("oversight/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    pi: belongsTo('site-user'),
    timelineEvents: hasMany('timeline-event', {
      polymorphic: true
    }),
    projectUsers: hasMany('project-user'),
    experiments: hasMany('experiment'),
    ndpResourceGroups: hasMany('ndp-resource-group'),
    ndpResourceFiles: hasMany('ndp-resource-file'),
    dataResourceGroups: hasMany('data-resource-group'),
    dataResourceFiles: hasMany('data-resource-file'),
    authors: attr('string'),
    collabGrantNum: attr('string'),
    collaborator: attr('string'),
    estCosts: attr('number'),
    funding: attr('string'),
    name: attr('string'),
    proposal: attr('string'),
    requester: attr('string'),
    screenId: attr('string'),
    sracNumber: attr('string'),
    status: attr('string'),
    totalCharges: attr('number'),
    studies: attr('number'),
    series: attr('number'),
    images: attr('number'),
    nondcmStudies: attr('number'),
    files: attr('number'),
    permissions: attr(),
    path: Ember.computed('id', function () {
      return "projects/".concat(this.id);
    }),
    numStudies: Ember.computed('studies', 'nondcmStudies', function () {
      var numStudies = Ember.get(this, 'studies') + Ember.get(this, 'nondcmStudies');
      return numStudies > 0 ? numStudies : null;
    }),
    numImages: Ember.computed('images', 'files', function () {
      var numImages = Ember.get(this, 'images') + Ember.get(this, 'files');
      return numImages > 0 ? numImages : null;
    }),
    searchIndex: Ember.computed('{name,screenId}', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'name', 'screenId'),
          name = _EmberGetProperties.name,
          screenId = _EmberGetProperties.screenId;

      return "".concat(name, "|").concat(screenId);
    })
  });

  _exports.default = _default;
});
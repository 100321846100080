define("oversight/pods/projects/show/data/experiments/show/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: {},
    _routeKey: 'experiments.show',
    model: function model(_ref) {
      var experiment_id = _ref.experiment_id;

      var _this$modelFor = this.modelFor('projects.show.data'),
          breadCrumbs = _this$modelFor.breadCrumbs;

      var experiment = this.store.findRecord('experiment', experiment_id);
      return Ember.RSVP.hash({
        breadCrumbs: breadCrumbs,
        experiment: experiment
      });
    },
    afterModel: function afterModel(model) {
      var breadCrumbs = model.breadCrumbs,
          experiment = model.experiment;
      var breadCrumb = this.breadCrumb;
      var name = Ember.get(experiment, 'name');
      Ember.set(model, 'breadCrumbs', breadCrumbs.concat(name));
      Ember.set(breadCrumb, 'title', name);
    }
  });

  _exports.default = _default;
});
define("oversight/pods/components/remove-named-entry/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    name: null,
    action: null,
    isActive: false,
    reset: function reset() {
      Ember.set(this, 'isActive', false);
    },
    actions: {
      activate: function activate() {
        Ember.set(this, 'isActive', true);
      },
      submit: function submit()
      /* changeset */
      {
        var _this = this;

        var action = this.action;

        if (action === null) {
          return;
        }

        Ember.set(this, 'isSaving', true);
        action().then(function () {
          if (!_this.isDestroyed) {
            _this.reset();
          }
        }).catch(function () {// noop
        }).finally(function () {
          if (!_this.isDestroyed) {
            Ember.set(_this, 'isSaving', false);
          }
        });
      },
      cancel: function cancel() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});
define("oversight/models/series", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    study: belongsTo('study'),
    images: hasMany('image'),
    seriesUid: attr('string'),
    seriesNumber: attr('string'),
    seriesDate: attr('string'),
    modality: attr('string'),
    numImages: attr('number'),
    sizeImages: attr('number'),
    equipment: attr('string'),
    institution: attr('string'),
    seriesDescription: attr('string'),
    seriesPath: attr('string'),
    dicomdirDone: attr('string'),
    filesetUid: attr('string'),
    modTime: attr('string'),
    thumbImageUrl: attr('string'),
    // Folder Interface
    name: Ember.computed.alias('idDescription'),
    parent: Ember.computed.alias('study'),
    children: Ember.computed.alias('images'),
    isDirectory: true,
    idDescription: Ember.computed('{seriesId,seriesDescription}', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'seriesId', 'seriesDescription'),
          seriesId = _EmberGetProperties.seriesId,
          seriesDescription = _EmberGetProperties.seriesDescription;

      return [seriesId, seriesDescription].map(function (part) {
        if (Ember.isPresent(part)) {
          return part;
        }
      }).compact().join(' ');
    }),
    seriesId: Ember.computed('seriesNumber', function () {
      return parseInt(Ember.get(this, 'seriesNumber'));
    }),
    baseUrl: Ember.computed('thumbImageUrl', function () {
      var thumbImageUrl = Ember.get(this, 'thumbImageUrl');

      if (thumbImageUrl === null) {
        return;
      }

      var parts = thumbImageUrl.split('/');
      return parts.slice(0, parts.length - 1).join('/');
    }),
    dicomDirUrl: Ember.computed('baseUrl', function () {
      var baseUrl = Ember.get(this, 'baseUrl');

      if (baseUrl === undefined) {
        return;
      }

      return "".concat(baseUrl, "/DICOMDIR");
    })
  });

  _exports.default = _default;
});
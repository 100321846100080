define("oversight/pods/projects/show/data/experiments/show/studies/show/series/route", ["exports", "oversight/pods/authorized/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadCrumb: null,
    _routeKey: 'series',
    model: function model() {
      var study = this.modelFor('projects.show.data.experiments.show.studies.show');
      return Ember.RSVP.hash({
        study: study
      });
    }
  });

  _exports.default = _default;
});
define("oversight/initializers/ember-links-with-follower", ["exports", "oversight/config/environment", "ember-links-with-follower/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var config = _environment.default['ember-links-with-follower'] || {};

    _configuration.default.load(config);
  }

  var _default = {
    name: 'ember-links-with-follower',
    initialize: initialize
  };
  _exports.default = _default;
});
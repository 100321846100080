define("oversight/pods/components/dicom-preview/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    _dwvApp: null,
    images: null,
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._setup();

      this._dwvApp = new dwv.App();
      var element = this.elementId;
      var scroll = (0, _jquery.default)("#".concat(element, " .scroll"));
      var zoom = (0, _jquery.default)("#".concat(element, " .zoom-pan"));
      var wl = (0, _jquery.default)("#".concat(element, " .window-level"));
      scroll.on('click', this._dwvApp.onChangeTool);
      zoom.on('click', this._dwvApp.onChangeTool);
      wl.on('click', this._dwvApp.onChangeTool);

      this._dwvApp.init({
        containerDivId: this.elementId,
        fitToWindow: true,
        // gui: ['tool'],
        tools: ['Scroll', 'ZoomAndPan', 'WindowLevel']
      });
    },
    willDestroyElement: function willDestroyElement() {
      var element = this.elementId;
      var scroll = (0, _jquery.default)("#".concat(element, " .scroll"));
      var zoom = (0, _jquery.default)("#".concat(element, " .zoom-pan"));
      var wl = (0, _jquery.default)("#".concat(element, " .window-level"));
      scroll.off('click');
      zoom.off('click');
      wl.off('click'); // remove resize listener

      window.onresize = null;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var images = this.images;
      var elementId = this.elementId;
      var currentSession = this.currentSession;
      var token = Ember.get(currentSession, 'token');

      if (!images || !elementId) {
        return;
      }

      var urls = images.slice(0, 50).map(function (image) {
        return Ember.get(image, 'url');
      });
      Ember.RSVP.all(urls).then(function (urls) {
        var filteredUrls = urls.filter(function (url) {
          return url;
        });
        var authorizedUrls = filteredUrls.map(function (url) {
          return "".concat(url, "?token=").concat(token);
        });

        _this._dwvApp.loadURLs(authorizedUrls);
      });
    },
    _setup: function _setup() {
      var element = this.elementId;
      var width = (0, _jquery.default)("#".concat(element)).width();
      dwv.gui.getElement = dwv.gui.base.getElement;
      dwv.gui.displayProgress = dwv.gui.base.displayProgress;
      dwv.gui.WindowLevel = dwv.gui.base.WindowLevel;

      dwv.gui.getWindowSize = function () {
        return {
          'width': width - 10,
          'height': width
        };
      };
    }
  });

  _exports.default = _default;
});
define("oversight/models/image", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    series: belongsTo('series'),
    imageUid: attr('string'),
    imageNumber: attr('string'),
    filename: attr('string'),
    imageSop: attr('string'),
    modTime: attr('string'),
    status: attr('string'),
    imageSize: attr('string'),
    anonymFlag: attr('string'),
    // Folder Interface
    name: Ember.computed.alias('imageNumber'),
    parent: Ember.computed.alias('series'),
    isDirectory: false,
    url: Ember.computed('series.baseUrl', 'filename', function () {
      var _this = this;

      var series = Ember.get(this, 'series');
      return series.then(function (series) {
        var baseUrl = Ember.get(series, 'baseUrl');
        var filename = Ember.get(_this, 'filename');

        if (baseUrl === undefined) {
          return;
        }

        return "".concat(baseUrl, "/").concat(filename);
      });
    })
  });

  _exports.default = _default;
});
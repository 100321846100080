define("oversight/pods/projects/show/dashboard-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yc1DVkM0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"loading\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-refresh fa-spin\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/projects/show/dashboard-loading/template.hbs"
    }
  });

  _exports.default = _default;
});
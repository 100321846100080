define("oversight/models/project-diary", ["exports", "oversight/models/timeline-event", "ember-data"], function (_exports, _timelineEvent, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _timelineEvent.default.extend({
    body: attr('string'),
    date: attr('date')
  });

  _exports.default = _default;
});
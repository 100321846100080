define("oversight/helpers/simple-format", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.simpleFormat = simpleFormat;
  _exports.default = void 0;

  var splitParagraphs = function splitParagraphs(text) {
    return text.split(/\n\n+/).map(function (text) {
      return text.replace(/([^\n]\n)(?=[^\n])/g, '$1<br>') || text;
    });
  };

  function simpleFormat(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        text = _ref2[0];

    var escapedText = Ember.Handlebars.Utils.escapeExpression(text);
    var paragraphs = splitParagraphs(escapedText).map(function (paragraph) {
      return "<p>".concat(paragraph, "</p>");
    });
    return Ember.String.htmlSafe(paragraphs.join("\n"));
  }

  var _default = Ember.Helper.helper(simpleFormat);

  _exports.default = _default;
});
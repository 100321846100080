define("oversight/pods/components/add-named-entry/component", ["exports", "ember-changeset-validations", "ember-changeset", "oversight/validations/name"], function (_exports, _emberChangesetValidations, _emberChangeset, _name) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    name: null,
    action: null,
    isActive: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.changeset = this._newChangeset();
    },
    reset: function reset() {
      Ember.set(this, 'isActive', false);
      Ember.set(this, 'changeset', this._newChangeset());
    },
    _newChangeset: function _newChangeset() {
      return new _emberChangeset.default({
        name: null
      }, (0, _emberChangesetValidations.default)(_name.default), _name.default);
    },
    actions: {
      activate: function activate() {
        Ember.set(this, 'isActive', true);
      },
      submit: function submit(changeset) {
        var _this = this;

        var action = this.action;

        if (action === null) {
          return;
        }

        Ember.set(this, 'isSaving', true);
        changeset.execute();
        action(Ember.get(changeset, 'name')).then(function () {
          _this.reset();
        }).catch(function () {// noop
        }).finally(function () {
          Ember.set(_this, 'isSaving', false);
        });
      },
      cancel: function cancel() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});
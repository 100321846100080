define("oversight/initializers/ember-preferences", ["exports", "ember-preferences/setup", "oversight/-ember-preferences-internal", "oversight/preferences"], function (_exports, _setup, _emberPreferencesInternal, _preferences) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var defaults = (0, _emberPreferencesInternal.default)();
    var userPreferences = (0, _preferences.default)(); // application.inject('route', 'foo', 'service:foo');

    (0, _setup.register)(application, Ember.assign(defaults, userPreferences));
  }

  var _default = {
    name: 'ember-preferences',
    initialize: initialize
  };
  _exports.default = _default;
});
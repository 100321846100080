define("oversight/preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // Edit app/-ember-preferences-internal.js to add/modify defaults.
  // This file is just a placeholder.
  function _default() {
    return {};
  }
});
define("oversight/pods/components/timeline-events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QypcQ3Nh",
    "block": "{\"symbols\":[\"events\",\"date\",\"event\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"timeline-event\",null,[[\"modelName\"],[\"comment\"]],{\"statements\":[[0,\"    \"],[1,[28,\"timeline-event-project-diary-form\",null,[[\"onSubmit\"],[[24,[\"onCreate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[28,\"group-by\",[\"dateInWords\",[24,[\"timelineEvents\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"divider\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[1,[23,2,[]],false],[9],[0,\"\\n      \"],[7,\"hr\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"timeline-event\",null,[[\"event\"],[[23,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/timeline-events/template.hbs"
    }
  });

  _exports.default = _default;
});
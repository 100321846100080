define("oversight/pods/components/remove-named-entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YVYF83nS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"activate\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-trash\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"ric-delete-dialog\",null,[[\"subject\",\"onSubmit\",\"onCancel\"],[[24,[\"name\"]],[28,\"action\",[[23,0,[]],\"submit\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/remove-named-entry/template.hbs"
    }
  });

  _exports.default = _default;
});
define("oversight/services/raven", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    callRaven: function callRaven() {},
    captureException: function captureException() {}
  });

  _exports.default = _default;
});
define("oversight/pods/components/timeline-event-resource-change/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k0ZioYxQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h5\",true],[8],[0,\"\\n  \"],[1,[24,[\"event\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[1,[24,[\"event\",\"content\",\"name\"]],false],[0,\" has been moved from \"],[1,[24,[\"event\",\"content\",\"from\"]],false],[0,\" to \"],[1,[24,[\"event\",\"content\",\"to\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "oversight/pods/components/timeline-event-resource-change/template.hbs"
    }
  });

  _exports.default = _default;
});
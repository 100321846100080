define("oversight/helpers/progress-in-words", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.progressInWords = progressInWords;
  _exports.default = void 0;

  function progressInWords(params
  /*, hash*/
  ) {
    var _params = (0, _slicedToArray2.default)(params, 4),
        totalItems = _params[0],
        remainingItems = _params[1],
        totalBytes = _params[2],
        transferredBytes = _params[3];

    var completedItems = totalItems - remainingItems;
    return "".concat(completedItems, " of ").concat(totalItems, " (").concat((transferredBytes / 1000000).toFixed(2), " of ").concat((totalBytes / 1000000).toFixed(0), " MB)");
  }

  var _default = Ember.Helper.helper(progressInWords);

  _exports.default = _default;
});
define("oversight/pods/projects/show/data/data-resource-groups/show/index/controller", ["exports", "ember-ric-utils/mixins/search-term", "ember-ric-utils/mixins/filtered-records", "ember-ric-utils/mixins/sort-by", "oversight/config/environment"], function (_exports, _searchTerm, _filteredRecords, _sortBy, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_searchTerm.default, _filteredRecords.default, _sortBy.default, {
    routeNamespace: _environment.default.namespace,
    sortProperty: 'updatedAt',
    sortAscending: false,
    records: Ember.computed.union('dataResourceGroups', 'dataResourceFiles'),
    preFilteredRecords: Ember.computed.filterBy('records', 'isDestroyed', false),
    sortedRecords: Ember.computed.sort('preFilteredRecords', 'sortOrder')
  });

  _exports.default = _default;
});
define("oversight/pods/components/force-reflow/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    target: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var target = this.target;
      var element = (0, _jquery.default)(target);
      var original = element.css('width');
      Ember.run.later(function () {
        element.css('width', "calc(".concat(original, " - 0.5px)"));
      }, 50);
      Ember.run.later(function () {
        element.css('width', original);
      }, 100);
    }
  });

  _exports.default = _default;
});
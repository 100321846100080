define("oversight/models/data-resource-file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    dataResourceGroup: belongsTo('data-resource-group', {
      inverse: 'dataResourceFiles'
    }),
    name: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    fileUrl: attr('string'),
    _moveTo: attr('string'),
    // Folder interface
    isDirectory: false,
    isResource: true,
    pathPrefix: null,
    url: Ember.computed('fileUrl', function () {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        resolve(Ember.get(_this, 'fileUrl'));
      });
    })
  });

  _exports.default = _default;
});
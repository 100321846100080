define("oversight/models/study", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    patient: belongsTo('patient'),
    series: hasMany('series'),
    studyid: attr('string'),
    studyDate: attr('date'),
    studyDescription: attr('string'),
    modTime: attr('date'),
    patMrn: attr('string'),
    patName: attr('string'),
    numberOfModalities: attr('number'),
    // Folder Interface
    name: Ember.computed('patMrn', 'studyid', function () {
      return "".concat(Ember.get(this, 'patMrn'), " ").concat(Ember.get(this, 'studyid'));
    }),
    parent: Ember.computed.alias('patient'),
    children: Ember.computed.alias('series'),
    updatedAt: Ember.computed.alias('modTime'),
    isDirectory: true,
    badge: 'dcm'
  });

  _exports.default = _default;
});
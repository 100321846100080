define("oversight/pods/projects/show/data/experiments/show/data-resource-groups/show/index/route", ["exports", "oversight/pods/authorized/route", "oversight/mixins/remove-data-resource", "oversight/mixins/move-data-resource"], function (_exports, _route, _removeDataResource, _moveDataResource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_removeDataResource.default, _moveDataResource.default, {
    breadCrumb: {
      title: 'data-resource-groups.show.index'
    },
    _routeKey: 'experiments.show',
    model: function model() {
      var project = this.modelFor('projects.show');

      var _this$modelFor = this.modelFor('projects.show.data.experiments.show'),
          experiment = _this$modelFor.experiment;

      var _this$modelFor2 = this.modelFor('projects.show.data.experiments.show.data-resource-groups.show'),
          breadCrumbs = _this$modelFor2.breadCrumbs,
          dataResourceGroup = _this$modelFor2.dataResourceGroup;

      var dataResourceGroups = Ember.get(dataResourceGroup, 'dataResourceGroups');
      var dataResourceFiles = Ember.get(dataResourceGroup, 'dataResourceFiles');

      this._reload(dataResourceGroups);

      this._reload(dataResourceFiles);

      return Ember.RSVP.hash({
        breadCrumbs: breadCrumbs,
        project: project,
        experiment: experiment,
        dataResourceGroup: dataResourceGroup,
        dataResourceGroups: dataResourceGroups,
        dataResourceFiles: dataResourceFiles
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.setProperties(controller, model);
    },
    _reload: function _reload(promise) {
      promise.then(function (collection) {
        collection.reload();
      });
    },
    actions: {
      reload: function reload() {
        return this.model();
      },
      addDataResourceGroup: function addDataResourceGroup(name) {
        var _this$modelFor3 = this.modelFor(this.routeName),
            dataResourceGroup = _this$modelFor3.dataResourceGroup,
            dataResourceGroups = _this$modelFor3.dataResourceGroups;

        var messages = this.flashMessages;
        var resourceGroup = this.store.createRecord('data-resource-group', {
          name: name,
          path: "".concat(Ember.get(dataResourceGroup, 'path'), "/").concat(name)
        });
        return resourceGroup.save().then(function () {
          messages.success("'".concat(name, "' has been created"));
          return dataResourceGroups.reload();
        }).catch(function () {
          messages.error("Failed to create '".concat(name, "'"));
        });
      }
    }
  });

  _exports.default = _default;
});